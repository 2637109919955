<template>
  <div class="certificate">
    <el-container>
      <el-header></el-header>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('certificate.BasicSoftware')" name="first">
          <div class="card-div1">
            <el-card v-loading="lodaing" class="box-card">
              <div
                slot="header"
                class="clearfix"
                style="display: flex; justify-content: space-between"
              >
                <div>{{ $t("certificate.BasicSoftware") }}</div>
                <div>
                  <el-popover
                    placement="top"
                    width="290"
                    trigger="hover"
                    @show="popShow"
                  >
                    <div style="text-align: left">
                      {{ $t("certificate.recommendedBenefit") }}
                      <a
                        href="https://ms.ytdevops.com/pubKnowledge/accffe88-d8ef-458d-80e6-5ea2ab55de07"
                        target="_blank"
                        >https://ms.ytdevops.com/pubKnowledge/accffe88-d8ef-458d-80e6-5ea2ab55de07</a
                      >
                    </div>
                    <el-button
                      style="margin-left: 20px"
                      type="primary"
                      slot="reference"
                      @click="Recommend"
                      >{{ $t("certificate.Recommend") }}
                    </el-button>
                  </el-popover>
                </div>
              </div>
              <div class="card-content">
                <div>
                  <span class="card-type" style="min-width: 140px">
                    {{ $t("certificate.CertificateType") }}
                  </span>
                  <span class="card-info">{{
                    returnLicenseType(basic_license_info.licenseType)
                  }}</span>
                </div>
                <div v-if="$store.getters.roles[0].name == 'SYSTEM_MANAGER'">
                  <span class="card-type" style="min-width: 140px">{{
                    $t("certificate.ExpirationDate")
                  }}</span>
                  <span
                    v-if="basic_license_info.dueDateTime != 2099"
                    class="card-info"
                    >{{
                      basic_license_info.dueDateTime
                        ? basic_license_info.dueDateTime.split(" ")[0]
                        : basic_license_info.dueDateTime
                    }}</span
                  >
                  <span
                    v-if="
                      basic_license_info.dueDateTime == 2099 ||
                      !basic_license_info.dueDateTime
                    "
                    class="card-info"
                    >{{ $t("certificate.NoExpirationDate") }}</span
                  >
                  <el-popover
                    placement="right"
                    width="290"
                    trigger="hover"
                    style="margin-left: 10%"
                  >
                    <div style="text-align: left">
                      {{ $t("certificate.Prompt") }}
                    </div>
                    <i
                      slot="reference"
                      class="iconfont icon-zwen"
                      style="cursor: pointer; color: #037abc; font-size: 16px"
                    >
                    </i>
                  </el-popover>
                </div>
                <div
                  v-if="
                    basic_license_info.licenseType == 'FLOAT_EDITION' ||
                    basic_license_info.licenseType == 'MIXED_EDITION'
                  "
                >
                  <span class="card-type" style="min-width: 140px">{{
                    $t("certificate.FloatMaximumNumberOfUsers")
                  }}</span>
                  <span
                    class="card-info"
                    style="min-width: 60px; display: inline-block"
                    >{{ basic_license_info.limitConcurrentUsers }}</span
                  >
                </div>
                <div
                  v-if="
                    basic_license_info.licenseType == 'ENTERPRISE_EDITION' ||
                    basic_license_info.licenseType == 'FREE_EDITION' ||
                    basic_license_info.licenseType == 'MIXED_EDITION'
                  "
                >
                  <span class="card-type" style="min-width: 140px">{{
                    $t("certificate.MaximumNumberOfUsers")
                  }}</span>
                  <span
                    class="card-info"
                    style="min-width: 60px; display: inline-block"
                    >{{ basic_license_info.accountLimit }}</span
                  >
                  <span class="card-type" style="min-width: 66px">{{
                    $t("certificate.Used")
                  }}</span>
                  <span class="card-info">{{ returnUsed() }}</span>
                </div>
              </div>
              <div class="card-footer">
                <el-button
                  v-if="!basic_license_info.dueDateTime"
                  type="primary"
                  @click="buy_content('basic')"
                  >{{ $t("certificate.ClickToBuy") }}
                </el-button>
              </div>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('certificate.FeeBasedComponents')"
          name="second"
        >
          <div class="card-div2">
            <el-card
              v-for="(item, index) in card_list"
              v-show="
                (item.prop === 'MGPT' &&
                  $store.state.project.tenantId ===
                    '586ee243-5501-4712-bf0b-0e36b5ea2ff6') ||
                item.prop !== 'MGPT'
              "
              :key="index"
              v-loading="lodaing"
              class="box-card"
            >
              <div slot="header" class="clearfix">
                <div class="pay_header">
                  <div>{{ item.name }}</div>

                  <i
                    class="iconfont icon-zhedie1"
                    style="
                      font-size: 28px;
                      cursor: pointer;
                      color: rgb(48, 100, 143);
                    "
                    @click="click_description(item.prop)"
                  ></i>
                </div>
              </div>
              <div class="card-content">
                <div class="license-type" style="padding: 16px 0">
                  <span class="card-type"
                    >{{ $t("certificate.CertificateType")
                    }}<span style="width: 20px; display: inline-block">
                      &nbsp;
                    </span></span
                  >
                  <span class="card-info">{{
                    returnLicenseType(basic_license_info.licenseType)
                  }}</span>
                </div>
                <div class="license-price" style="height: 27px">
                  <span class="card-type"
                    >{{ $t("certificate.UpgradePrice") }}
                    <span>
                      <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">
                          {{ $t("certificate.PublicCloudVersion") }}
                        </div>
                        <i
                          class="el-icon-question"
                          style="
                            font-size: 16px;
                            vertical-align: top;
                            color: rgba(128, 128, 128, 1);
                          "
                        ></i>
                      </el-tooltip>
                    </span>
                  </span>
                  <span
                    style="display: inline-block; height: 100%"
                    @mouseenter="item.open_price = true"
                    @mouseleave="item.open_price = false"
                  >
                    <i
                      v-if="!item.open_price"
                      class="iconfont icon-yincang"
                      style="font-size: 24px; color: rgb(42, 129, 227)"
                      @mouseenter="item.open_price = true"
                    >
                    </i>
                    <span v-else class="card-info" style="cursor: default">{{
                      item.price
                    }}</span>
                  </span>
                </div>
                <div>
                  <div
                    class="license-type"
                    :style="
                      item.effective
                        ? { padding: '16px 0 0' }
                        : { padding: '19px 0' }
                    "
                  >
                    <div
                      v-show="
                        item.effective == true &&
                        $store.getters.roles[0].name == 'SYSTEM_MANAGER'
                      "
                    >
                      <span class="card-type"
                        >{{ $t("certificate.ExpirationDate")
                        }}<span style="width: 20px; display: inline-block">
                          &nbsp;
                        </span></span
                      >
                      <span class="card-info">{{ item.dueDateTime }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!item.hasOwnProperty('effective')"
                class="card-footer button-arr"
              >
                <el-button type="primary" @click="submitPhone(item.prop)">
                  {{ $t("certificate.ClickInstall") }}
                </el-button>
              </div>
              <div
                v-else-if="item.hasOwnProperty('effective') && !item.effective"
                class="card-footer-pay button-arr"
              >
                <el-button
                  type="primary"
                  style="cursor: pointer"
                  @click="buy_content(item.prop)"
                >
                  {{ $t("certificate.Expired") }}
                </el-button>
              </div>
              <div
                v-else-if="item.effective"
                class="card-footer-pay button-arr"
              >
                <el-button type="primary" class="button-buy">
                  {{ $t("certificate.Installed") }}
                </el-button>
              </div>
            </el-card>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-container>
    <payDialog
      :dialog-visible.sync="dialogVisible"
      :title_text="title_text"
      :tip_text="tipsText"
    ></payDialog>
    <ContactPhoneDialog
      :dialogVisible="dialogVisiblePhone"
      @cancelDialog="cancelDialogPhone"
    ></ContactPhoneDialog>
    <el-dialog
      :visible="show_description"
      :close-on-click-modal="true"
      :show-close="true"
      :before-close="show_description_close"
      width="70%"
      custom-class="license_descrition_dialog"
    >
      <div v-for="(item, index) in card_list" :key="index">
        <div
          v-if="show_description_type == item.prop"
          class="card license_descrition_dialog_content"
        >
          <el-row type="flex" class="card-top">
            <el-col :span="4">
              <div>
                <img
                  :src="require('@/assets/img/license_' + item.prop + '.png')"
                  alt=""
                  class="border-color"
                />
              </div>
            </el-col>
            <el-col
              :span="20"
              style="display: flex; flex-direction: column; padding-right: 30px"
            >
              <el-row
                type="flex"
                style="
                  flex: 1;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
                ><span class="clearfix">{{ item.name }}</span>
                <div
                  v-if="!item.hasOwnProperty('effective')"
                  class="card-footer button-arr"
                >
                  <el-button type="primary" @click="submitPhone(item.prop)"
                    >{{ $t("certificate.ClickInstall") }}
                  </el-button>
                </div>
                <div
                  v-else-if="
                    item.hasOwnProperty('effective') && !item.effective
                  "
                  class="card-footer-pay button-arr"
                  style="cursor: pointer"
                >
                  <el-button
                    type="primary"
                    style="cursor: pointer"
                    @click="buy_content(item.prop)"
                  >
                    {{ $t("certificate.Expired") }}
                  </el-button>
                </div>
                <div
                  v-else-if="item.effective"
                  class="card-footer-pay button-arr"
                >
                  <el-button type="primary" class="button-buy">
                    {{ $t("certificate.Installed") }}
                  </el-button>
                </div>
              </el-row>
              <el-row
                type="flex"
                class="card-top-des"
                justify="start"
                style="flex: 1"
              >
                <span class="card-type"
                  >{{ $t("certificate.CertificateType")
                  }}<span style="width: 20px; display: inline-block">
                    &nbsp;
                  </span></span
                >
                <span class="card-info">{{
                  returnLicenseType(basic_license_info.licenseType)
                }}</span>
              </el-row>
              <el-row type="flex" class="card-top-des" style="flex: 1">
                <span class="card-type">
                  {{ $t("certificate.UpgradePrice") }}
                  <span>
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">
                        {{ $t("certificate.PublicCloudVersion") }}
                      </div>
                      <i
                        class="el-icon-question"
                        style="
                          font-size: 16px;
                          vertical-align: top;
                          color: rgba(128, 128, 128, 1);
                        "
                      ></i>
                    </el-tooltip>
                  </span>
                </span>
                <i
                  v-if="!item.open_price1"
                  class="iconfont icon-yincang"
                  style="font-size: 24px; color: rgb(42, 129, 227)"
                  @mouseenter="item.open_price1 = true"
                ></i>
                <span
                  v-else
                  class="card-info"
                  style="cursor: default"
                  @mouseleave="item.open_price1 = false"
                  >{{ item.price }}</span
                >
              </el-row>
              <el-row
                type="flex"
                class="card-top-des"
                justify="start"
                style="flex: 1"
              >
                <div class="license-type">
                  <div
                    v-show="
                      item.effective == true &&
                      $store.getters.roles[0].name == 'SYSTEM_MANAGER'
                    "
                  >
                    <span class="card-type"
                      >{{ $t("certificate.ExpirationDate")
                      }}<span style="width: 20px; display: inline-block">
                        &nbsp;
                      </span></span
                    >
                    <span class="card-info">{{ item.dueDateTime }}</span>
                  </div>
                </div>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="card-middle-content">
            <div
              style="
                font-size: 18px;
                font-weight: 500;
                color: rgba(42, 130, 228, 1);
                text-align: left;
              "
            >
              {{ $t("certificate.Introduction") }}
            </div>
          </el-row>

          <el-row class="card-middle-content">
            <span
              style="
                font-size: 16px;
                font-weight: 400;
                color: rgba(112, 112, 112, 1);
                text-align: left;
              "
              >{{ item.desc }}</span
            >
            <span
              v-if="item.name == $t('certificate.AIRobot')"
              style="
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                color: rgba(112, 112, 112, 1);
              "
              >{{ $t("certificate.AIRobotTip") }}</span
            >
          </el-row>
          <el-row class="card-middle-content">
            <span
              v-if="
                item.prop != 'ASPICE' &&
                item.prop != 'AVIONICS' &&
                item.prop != 'SecCT'
              "
              style="
                font-size: 16px;
                font-weight: 400;
                color: rgba(42, 130, 228, 1);
                text-align: left;
              "
            >
              {{
                item.prop == "FTA" ||
                item.prop == "DFMEA" ||
                item.prop == "TARA"
                  ? $t("certificate.FTAUnpaidUsers")
                  : $t("certificate.UnpaidUsers")
              }}</span
            >
            <span
              v-else
              style="
                font-size: 16px;
                font-weight: 400;
                color: rgba(42, 130, 228, 1);
                text-align: left;
              "
              >{{ $t("certificate.unSupportTrial") }}</span
            >
          </el-row>
          <el-row class="card-middle-content">
            <div
              style="
                font-size: 18px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                text-align: left;
              "
            >
              {{ $t("certificate.Preview") }}:
            </div>
          </el-row>
          <el-row class="card-middle-swipper">
            <el-carousel
              width="800px"
              height="450px"
              type="card"
              :autoplay="false"
            >
              <el-carousel-item
                v-for="(i, index) in item.img"
                :key="i.name"
                class="card-middle-swipper-item"
              >
                <el-image fit="fit" :src="i" :preview-src-list="item.img" />
                <!-- <el-image fit="fit" :src="i" /> -->
              </el-carousel-item>
            </el-carousel>
          </el-row>
        </div>
      </div>
    </el-dialog>
    <!-- 推荐的弹窗 -->
    <el-dialog
      :title="$t('certificate.Copy')"
      :visible.sync="dialogVisibleRecommend"
      width="40%"
    >
      <div>{{ this.RecommendLink }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CopyRecommendLink">{{
          $t("btn.copyBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <label style="display: block">
      <textarea ref="text_area" style="opacity: 0"></textarea>
    </label>
  </div>
</template>

<script>
import {
  get_basic_license,
  get_component_license,
} from "@/network/license/index.js";
import payDialog from "@/components/payFeatureValidDialog";
import { getPhoneInfo } from "../../network/login";
import ContactPhoneDialog from "@/components/contactPhoneDialog";
import { inviteMark } from "@/network/login";
import { recommendButtong } from "@/network/eventTracking";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    payDialog,
    ContactPhoneDialog,
  },
  data() {
    return {
      activeName: "first",
      basic_license_info: {},
      dialogVisible: false,
      title_text: this.$t("addMindMap.tip12"),
      tipsText: "",
      component_license_info: [],
      lodaing: true,
      card_list: [
        {
          name: this.$t("gantt.info1"),
          prop: "gant",
          license: "GANTT_CHART",
          price: "9" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_gant.png",
          desc: this.$t("certificate.GanttDes"),
          img: [
            "https://automind-oss.ytdevops.com/static/收费组件图片/甘特图/1.png",
            "https://automind-oss.ytdevops.com/static/收费组件图片/甘特图/2.png",
            "https://automind-oss.ytdevops.com/static/收费组件图片/甘特图/3.png",
          ],
        },
        {
          name: this.$t("certificate.AutomatedWorkflow"),
          prop: "workflow",
          license: "WORKFLOW_AUTOMATION",
          price: "6" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "../../assets/img/license_workflow.png",
          desc: this.$t("certificate.AutomatedWorkflowDes"),
          img: [
            "https://automind-oss.ytdevops.com/static/收费组件图片/自动化工作流/1.png",
            "https://automind-oss.ytdevops.com/static/收费组件图片/自动化工作流/2.png",
            "https://automind-oss.ytdevops.com/static/收费组件图片/自动化工作流/3.png",
          ],
        },
        {
          name: this.$t("certificate.VModelPanorama"),
          prop: "vmodel",
          license: "VMODEL_ANALYZE_CHART",
          price: "12" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_vmodel.png",
          desc: this.$t("certificate.VModelPanoramaDes"),
          img: [
            "https://automind-oss.ytdevops.com/static/收费组件图片/V模型全景图/1.png",
            "https://automind-oss.ytdevops.com/static/收费组件图片/V模型全景图/2.jpg",
            "https://automind-oss.ytdevops.com/static/收费组件图片/V模型全景图/3.png",
            "https://automind-oss.ytdevops.com/Frontend/static/收费组件图片/V模型全景图/4.jpg",
          ],
        },

        {
          name: this.$t("certificate.WModelPanorama"),
          prop: "wmodel",
          license: "WMODEL_ANALYZE_CHART",
          price: "15" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_vmodel.png",
          desc: this.$t("certificate.WModelPanoramaDes"),
          img: [
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/Wmodel/20240417-164831.jpeg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/Wmodel/20240417-164827.jpeg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/Wmodel/20240417-164823.jpeg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/Wmodel/20240417-164817.jpeg",
          ],
        },
        {
          name: this.$t("certificate.AIRobot"),
          prop: "ai",
          license: "AI_ROBOT",
          // price: "19" + this.$t("certificate.Price"),
          price: this.$t("certificate.AccordingToTheModel"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_vmodel.png",
          desc: this.$t("certificate.AIRobotDes"),
          img: [
            "https://automind-oss.ytdevops.com/static/收费组件图片/AI机器人/1.jpg",
            "https://automind-oss.ytdevops.com/static/收费组件图片/AI机器人/2.jpg",
            "https://automind-oss.ytdevops.com/static/收费组件图片/AI机器人/3.jpg",
          ],
        },
        {
          name: this.$t("certificate.AutomatedTesting"),
          prop: "test",
          license: "SYNC_TEST_REPORT",
          price: "12" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_vmodel.png",
          desc:
            this.$t("certificate.AutomatedTestingDes") +
            ": " +
            "https://ms.ytdevops.com//pubKnowledge/1db7562f-6f55-4d30-907c-390163fe43b8",
          img: [
            "https://automind-oss.ytdevops.com/static/收费组件图片/自动化测试/1.jpg",
            "https://automind-oss.ytdevops.com/static/收费组件图片/自动化测试/2.jpg",
            "https://automind-oss.ytdevops.com/static/收费组件图片/自动化测试/3.jpg",
          ],
        },
        {
          name: this.$t("certificate.KnowledgeBase"),
          prop: "knowledge",
          license: "KNOWLEDGE_BASE",
          price: "15" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_knowledge.png",
          desc: this.$t("certificate.KnowledgeBaseDes"),
          img: [
            "https://automind-oss.ytdevops.com/static/收费组件图片/知识库/1.png",
            "https://automind-oss.ytdevops.com/static/收费组件图片/知识库/2.png",
            "https://automind-oss.ytdevops.com/static/收费组件图片/知识库/3.png",
          ],
        },
        {
          name: this.$t("certificate.HARA"),
          prop: "HARA",
          license: "HARA",
          price: "5" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_knowledge.png",
          desc: this.$t("certificate.HARADes"),
          img: [
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/HARA/805241021713927384227-image.png",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/HARA/7cfb5baf1713927423715-image.png",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/HARA/5ee34acb1713927323668-image.png",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/HARA/15ed6bcd1713927341784-image.png",
          ],
        },
        {
          name: this.$t("certificate.DFMEA"),
          prop: "DFMEA",
          license: "DFMEA",
          price: "12" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_knowledge.png",
          desc: this.$t("certificate.DFMEADes"),
          img: [
            "https://automind-oss.ytdevops.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/DFMEA/dfmea00.jpeg",
            "https://automind-oss.ytdevops.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/DFMEA/dfmea01.jpeg",
            "https://automind-oss.ytdevops.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/DFMEA/dfmea02.jpeg",
          ],
        },
        {
          name: this.$t("certificate.FTA"),
          prop: "FTA",
          license: "FTA",
          price: "12" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_knowledge.png",
          desc: this.$t("certificate.FTADesc"),
          img: [
            "https://automind-oss.ytdevops.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6/FTA/files.jpg",
            "https://automind-oss.ytdevops.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6/FTA/ftaFile.jpg",
            "https://automind-oss.ytdevops.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6/FTA/ftaView.jpg",
          ],
        },
        {
          name: "TARA",
          prop: "TARA",
          license: "TARA",
          price: "12" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_knowledge.png",
          desc: this.$t("certificate.TARADesc"),
          img: [
            "https://automind-oss.ytdevops.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/17056323845701.png",
            "https://automind-oss.ytdevops.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/17056323845702.png",
            "https://automind-oss.ytdevops.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/17056323845703.png",
          ],
        },
        {
          name: this.$t("certificate.ASPICETitle"),
          prop: "ASPICE",
          license: "PROJECT_TEMPLATE_ASPICE",
          price: "50000" + this.$t("certificate.PricePerSet"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_ASPICE.png",
          desc: this.$t("certificate.ASPICEDesc"),
          img: [
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/%E4%BB%98%E8%B4%B9%E9%A1%B9%E7%9B%AE%E6%A8%A1%E6%9D%BF/20240411-095650.jpeg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/%E4%BB%98%E8%B4%B9%E9%A1%B9%E7%9B%AE%E6%A8%A1%E6%9D%BF/20240411-095644.jpeg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/%E4%BB%98%E8%B4%B9%E9%A1%B9%E7%9B%AE%E6%A8%A1%E6%9D%BF/20240411-095639.jpeg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/%E4%BB%98%E8%B4%B9%E9%A1%B9%E7%9B%AE%E6%A8%A1%E6%9D%BF/20240411-095631.jpeg",
          ],
        },
        {
          name: this.$t("certificate.AVIONICSTitle"),
          prop: "AVIONICS",
          license: "PROJECT_TEMPLATE_AVIONICS",
          price: "50000" + this.$t("certificate.PricePerSet"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_AVIONICS.png",
          desc: this.$t("certificate.AVIONICSDesc"),
          img: [
            "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/01JFF0C6GHS6S6EZJ4V94HVC3E1.jpg",
            "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/01JFF0CV7RQ4389BF951ZNP7XS2.jpg",
            "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/01JFF0D3QH24YEY6Y8F19RPP8K3.jpg",
            "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/01JFF0DBXFE5H9647VNPTHM7XQ4.jpg",
          ],
        },

        {
          name: this.$t("certificate.MappingGPT"),
          prop: "MGPT",
          license: "MGPT",
          price: "28" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_MGPT.png",
          desc: this.$t("certificate.MGPTDesc"),
          img: [
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/MGPT/MGPT1.jpg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/MGPT/MGPT2.jpg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/MGPT/MGPT3.jpg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/MGPT/MGPT4.jpg",
          ],
        },
        {
          name: "SecCT",
          prop: "SecCT",
          license: "SecCT",
          price: "**" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_SecCT.png",
          desc: this.$t("certificate.SecCTDesc"),
          img: [
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/SecCT/SecCT1.png",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/SecCT/SecCT2.png",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/SecCT/SecCT3.png",
          ],
        },
        {
          name: this.$t("certificate.third"),
          prop: "THIRD_LINK",
          license: "THIRD_LINK",
          price: "15" + this.$t("certificate.Price"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_knowledge.png",
          desc: this.$t("certificate.thirdDes"),
          img: [
            "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/t1.png",
            "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/t2.png",
            "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%94%B6%E8%B4%B9%E7%BB%84%E4%BB%B6%E5%9B%BE%E7%89%87/t3.png",
          ],
        },
        {
          name: this.$t("certificate.safetyGPT"),
          prop: "safety",
          license: "SAFETY_GPT",
          price: this.$t("certificate.free"),
          open_price: false,
          open_price1: false,
          logo: "@/assets/img/license_safety.png",
          desc: this.$t("certificate.safetyGPTDes"),
          img: [
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/safety/safety1.jpg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/safety/safety2.jpg",
            "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/safety/safety3.jpg",
          ],
        },
      ],
      open_price_gant: false,
      open_price_workflow: false,
      open_price_knowledge: false,
      open_price_gant1: false,
      open_price_vmodel: false,
      open_price_vmodel1: false,
      open_price_workflow1: false,
      open_price_knowledge1: false,
      show_description: false,
      detail: {},
      activeName: "first",
      show_description_type: "",
      // 判断手机号是否为空
      isphone: true,
      // 是否显示添加手机号
      dialogVisiblePhone: false,
      // 推荐弹窗
      dialogVisibleRecommend: false,
      RecommendLink: "",
      nowRouterTraget: "",
      // 埋点节流
      timer_throttle: null,
    };
  },
  computed: {
    ...mapGetters(["user_info", "user_list"]),
  },
  mounted() {
    this.getPayInfo();
    this.getPhoneInfos();
    this.nowRouterTraget = this.$route.query.target;
    if (this.nowRouterTraget) {
      this.activeName = "second";
      this.show_description = true;
      this.show_description_type = this.nowRouterTraget;
    }
  },
  methods: {
    // 返回已使用用户数
    returnUsed() {
      let num = 0;
      Object.values(this.user_list).forEach((user) => {
        if (user.deleted !== 1 && user.userLicenseType === "FIXED") {
          num++;
        }
      });
      return num;
    },
    // 返回类型
    returnLicenseType(type) {
      const map = {
        FREE_EDITION: this.$t("certificate.ENTERPRISE_EDITION"),
        ENTERPRISE_EDITION: this.$t("certificate.ENTERPRISE_EDITION"),
        FLOAT_EDITION: this.$t("certificate.FLOAT_EDITION"),
        MIXED_EDITION: this.$t("certificate.MIXED_EDITION"),
      };
      return map[type];
    },
    popShow() {
      if (!this.timer_throttle) {
        this.timer_throttle = setTimeout(() => {
          const timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
          const email = this.user_info.email;
          const accountId = this.user_info.accountId;
          const tenantId = this.user_info.tenantId;
          const params = {
            data: {
              timestamp,
              email,
              accountId,
              tenantId,
            },
          };
          recommendButtong(params).then((res) => {
            this.timer_throttle = null;
          });
        }, 5000);
      }
    },
    // 复制推荐链接
    CopyRecommendLink() {
      let text2 = this.$refs["text_area"];
      text2.innerText =
        this.$t("certificate.shareCopyText") + " " + this.RecommendLink;
      text2.select();
      document.execCommand("copy");
      this.$message({
        message: this.$t("projectDetails.copyed"),
        type: "success",
      });
    },
    // 推荐
    Recommend() {
      this.dialogVisibleRecommend = true;
      inviteMark().then((res) => {
        this.RecommendLink = `${window.location.origin}/login/register?inviteMark=${res}`;
      });
    },
    // 获取手机号信息
    getPhoneInfos() {
      getPhoneInfo().then((res) => {
        if (res.contactPhone) {
          this.isphone = false;
        }
      });
    },
    // 取消手机号弹窗
    cancelDialogPhone(value) {
      this.dialogVisiblePhone = false;
      if (value == "update") {
        // 重新加载当前页面
        location.reload();
      }
    },
    // 弹出提交手机号
    submitPhone(type) {
      if (type === "ASPICE") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.ASPICEPurchaseTips");
      } else if (this.isphone) {
        this.dialogVisiblePhone = true;
      } else {
        this.buy_content(type);
      }
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    buy_content(type) {
      if (type == "basic") {
        // 购买基础软件
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.BasicSoftwarePurchaseTips");
      } else if (type == "gant") {
        this.dialogVisible = true;
        this.tipsText = this.$t("gantt.Tip");
      } else if (type == "workflow") {
        this.dialogVisible = true;
        this.tipsText = this.$t("editFileType.workflow.dialog.tip10");
      } else if (type == "knowledge") {
        this.dialogVisible = true;
        this.tipsText = this.$t("knowledge.KnowledgeBasePaidFeature");
      } else if (type == "vmodel") {
        this.dialogVisible = true;
        this.tipsText = this.$t("chart.message.ms16");
      } else if (type == "wmodel") {
        this.dialogVisible = true;
        this.tipsText = this.$t("chart.message.ms32");
      } else if (type == "ai") {
        this.dialogVisible = true;
        this.tipsText = this.$t("AI.tip7");
      } else if (type == "test") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.AutomationTestingPurchaseTips");
      } else if (type == "HARA") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.HARAPurchaseTips");
      } else if (type == "THIRD_LINK") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.thirdPurchaseTips");
      } else if (type == "DFMEA") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.DFMEAPurchaseTips");
      } else if (type == "FTA") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.FTAPurchaseTips");
      } else if (type == "TARA") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.TARAPurchaseTips");
      } else if (type == "ASPICE") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.ASPICEPurchaseTips");
      } else if (type == "AVIONICS") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.AVIONICSPurchaseTips");
      } else if (type == "MGPT") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.ASPICEPurchaseTips");
      } else if (type == "SecCT") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.SecCTPurchaseTips");
      } else if (type == "safety") {
        this.dialogVisible = true;
        this.tipsText = this.$t("certificate.safetyGPTPurchaseTips");
      }
    },
    getPayInfo() {
      this.lodaing = true;
      // 获取基础软件信息
      get_basic_license().then((res) => {
        this.basic_license_info = res;
        // 获取收费组件信息
        get_component_license().then((res) => {
          for (let i = 0; i < this.card_list.length; i++) {
            for (let j = 0; j < res.length; j++) {
              if (this.card_list[i].license == res[j].feature) {
                this.card_list[i]["effective"] = res[j].effective;
                this.card_list[i]["dueDateTime"] =
                  res[j].dueDateTime.split(" ")[0];
                break;
              }
            }
          }
          this.component_license_info = res;
          this.lodaing = false;
        });
      });

      // 获取收费组件信息
    },
    click_description(type) {
      this.show_description = true;
      if (type == "gant") {
        this.show_description_type = "gant";
      } else if (type == "workflow") {
        this.show_description_type = "workflow";
      } else if (type == "vmodel") {
        this.show_description_type = "vmodel";
      } else if (type == "wmodel") {
        this.show_description_type = "wmodel";
      } else if (type == "ai") {
        this.show_description_type = "ai";
      } else if (type == "test") {
        this.show_description_type = "test";
      } else if (type == "HARA") {
        this.show_description_type = "HARA";
      } else if (type == "THIRD_LINK") {
        this.show_description_type = "THIRD_LINK";
      } else if (type == "DFMEA") {
        this.show_description_type = "DFMEA";
      } else if (type == "FTA") {
        this.show_description_type = "FTA";
      } else if (type == "TARA") {
        this.show_description_type = "TARA";
      } else if (type == "ASPICE") {
        this.show_description_type = "ASPICE";
      } else if (type == "AVIONICS") {
        this.show_description_type = "AVIONICS";
      } else if (type == "MGPT") {
        this.show_description_type = "MGPT";
      } else if (type == "SecCT") {
        this.show_description_type = "SecCT";
      } else if (type == "knowledge") {
        this.show_description_type = "knowledge";
      } else {
        this.show_description_type = "safety";
      }
    },
    show_description_close() {
      this.show_description = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-header {
  height: auto !important;
}

::v-deep .el-card__header {
  padding-left: 24px;
  border: 0;
}

::v-deep .el-card__body {
  padding: 0;
  padding-left: 24px;
  height: 100%;
}
// .card-middle-content {
//   word-break: break-word;
// }
.clearfix {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);

  > div {
    text-align: left;
  }
}

.card-content {
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  height: 100%;

  > div {
    text-align: left;
  }

  .description {
    // max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 20px;
    font-weight: 400;
    color: rgba(128, 128, 128, 1);
    text-align: left;
    padding-right: 24px;
  }
}

.card-footer {
  padding: 0 24px 24px 0;
  display: flex;
  justify-content: flex-end;

  .el-button {
    height: 36px;
    border-radius: 2px;
    background: rgba(1, 76, 170, 1);
    font-size: 16px;
    font-weight: 400;
  }
}

.card-type {
  padding-right: 16px;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
  text-align: left;
}

.card-info {
  font-size: 16px;
  font-weight: 400;
  color: rgba(42, 130, 228, 1);
  text-align: left;
}

.card-footer-pay {
  padding: 0 24px 24px 0;
  display: flex;
  justify-content: flex-end;

  .el-button {
    height: 36px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 400;
    color: rgba(128, 128, 128, 1);
    border: 1px solid rgba(128, 128, 128, 1);
  }

  .el-button:hover {
    cursor: default;
  }
}

.card-div1 {
  > .el-card {
    // flex: 1;
    width: 483px;

    margin: 20px;
  }

  .card-content {
    > div {
      padding-bottom: 16px;
    }
  }
}

.card-div2 {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  height: 80vh;
  overflow: auto;

  > .el-card {
    // flex: 1;
    width: calc((100% - 120px) / 4);
    // height: 220px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
  }
}

.pay_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card {
  // height: 85vh;
  // width: 65vw;
  overflow: scroll;
  border-radius: 15px;

  &-top {
    border-bottom: 1px solid rgba(222, 222, 222, 1);
    padding-bottom: 40px;

    &-name {
      font-size: 30px;
      font-weight: bold;
      margin: 10px;
    }

    &-des {
      justify-content: start;
      align-items: center;

      &-content {
        display: flex;
        justify-content: flex-start;
        font-size: 20px;

        a {
          color: rgba(112, 112, 112, 1);
        }

        color: rgba(112, 112, 112, 1);
      }

      &-tag {
        display: flex;
        justify-content: center;
        border-radius: 10px;
        background: rgba(48, 100, 143, 1);
        color: white;
        width: 50px;
        height: 20px;
      }
    }

    &-version {
      margin: 10px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: rgba(112, 112, 112, 1);

      &-number {
        display: flex;
        justify-content: flex-start;
      }

      &-name {
        display: flex;
        justify-content: flex-start;
      }
    }

    &-btn {
      background: rgba(48, 100, 143, 1);
      color: white;
    }
  }

  &-middle {
    font-size: 18px;
    font-weight: 500;
    color: rgba(42, 130, 228, 1);

    &-content {
      text-align: left;
      margin: 30px;
    }

    &-swipper {
      margin: 30px;
      text-align: left;

      &-item {
        width: 800px;
        height: 450px;
        background-size: cover;
      }
    }
  }
}

.border-color {
  padding: 8px;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 15px;
  background: rgba(255, 255, 255, 1);
  width: 150px;
  height: 150px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
}

.button-buy:hover {
  background: white !important;
  color: gray !important;
  border: 1px solid rgba(128, 128, 128, 1) !important;
}
</style>
<style lang="scss">
.license_descrition_dialog {
  margin-bottom: 0;
  margin-top: 5vh !important;
  overflow: hidden;
  height: 90vh;
  &_content {
    height: 84vh;
    overflow: scroll;
  }
}
</style>
